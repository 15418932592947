/* eslint-disable linebreak-style */
import es from 'vuetify/es5/locale/es';

export default {
  label: 'Español',
  login: {
    login: 'Iniciar Sesión',
    username: 'Nombre de usuario',
    password: 'Contraseña',
  },
  menu: {
    home: 'Home',
    reports: 'Reportes',
    users: 'Usuarios',
    cameras: 'Camaras',
    devices: 'Unidades',
    calendars: 'Calendarios',
    drivers: 'Conductores',
    groups: 'Grupos',
    maintenance: 'Mantenimiento',
    geofences: 'Geocercas',
    incidents: 'Incidentes',
    lpr: 'Analiticos',
    sensor: 'Botones de pánico',
    fuelPrice: 'Precio del combustible',
    deviceCategories: 'Categorias de dispositivos',
    'video-servers': 'Servidores de video',
  },
  'video-servers': {
    name: 'Servidor de video',
    plural_name: 'Servidores de video',
    create: 'Nuevo servidor de video',
    update: 'Actualizar servidor de video',
    read: 'Ver servidor de video',
    delete: 'Eliminar servidor de video',
    delete_confirm: '¿Estás seguro de eliminar el servidor de video?'

  },
  users: {
    name: 'Usuario',
    plural_name: 'Usuarios',
    create: 'Nuevo usuario',
    update: 'Actualizar usuario',
    read: 'Ver usuario',
    delete: 'Eliminar usuario',
    delete_confirm: 'Estás seguro de eliminar el usuario?',
    permissions: 'Permisos'
  },
  devices: {
    name: 'Unidad',
    plural_name: 'Unidades',
    create: 'Nuevo unidad',
    update: 'Actualizar unidad',
    read: 'Ver unidad',
    delete: 'Eliminar unidad',
    delete_confirm: 'Estás seguro de eliminar el unidad?'
  },
  'device-categories': {
    name: 'Categoria de dispositivo',
    plural_name: 'Categorias de dispositivos',
    create: 'Nuevo categoria de dispositivo',
    update: 'Actualizar categoria de dispositivo',
    read: 'Ver categoria de dispositivo',
    delete: 'Eliminar categoria de dispositivo',
    delete_confirm: 'Estás seguro de eliminar la categoria de dispositivo?'
  },
  calendars: {
    name: 'Calendario',
    plural_name: 'Calendarios',
    create: 'Nuevo calendario',
    update: 'Actualizar calendario',
    read: 'Ver calendario',
    delete: 'Eliminar calendario',
    delete_confirm: 'Estás seguro de eliminar el calendario?'
  },
  drivers: {
    name: 'Conductor',
    plural_name: 'Conductores',
    create: 'Nuevo conductor',
    update: 'Actualizar conductor',
    read: 'Ver conductor',
    delete: 'Eliminar conductor',
    delete_confirm: 'Estás seguro de eliminar el conductor?'
  },
  groups: {
    name: 'Grupo',
    plural_name: 'Grupos',
    create: 'Nuevo grupo',
    update: 'Actualizar grupo',
    read: 'Ver grupo',
    delete: 'Eliminar grupo',
    delete_confirm: 'Estás seguro de eliminar el grupo?'
  },
  cameras: {
    name: 'Camara',
    plural_name: 'Camaras',
    create: 'Nueva camara',
    update: 'Actualizar camara',
    read: 'Ver camara',
    delete: 'Eliminar camara',
    delete_confirm: 'Estás seguro de eliminar la camara?'
  },
  geofences: {
    name: 'Geocerca',
    plural_name: 'Geocercas',
    create: 'Nueva geocerca',
    update: 'Actualizar geocerca',
    read: 'Ver geocerca',
    delete: 'Eliminar geocerca',
    delete_confirm: 'Estás seguro de eliminar la geocerca?'
  },
  incidents: {
    name: 'Incidente',
    plural_name: 'Incidentes',
    create: 'Nuevo incidente',
    update: 'Actualizar incidente',
    read: 'Ver incidente',
    delete: 'Eliminar incidente',
    delete_confirm: 'Estás seguro de eliminar el incidente?'
  },
  lpr: {
    name: 'Analitico',
    plural_name: 'Analiticos',
    create: 'Nuevo analitico',
    update: 'Actualizar analitico',
    read: 'Ver analitico',
    delete: 'Eliminar analitico',
    delete_confirm: 'Estás seguro de eliminar el analitico?'
  },
  sensor: {
    name: 'Botón de pánico',
    plural_name: 'Botones de pánico',
    create: 'Nuevo botón de pánico',
    update: 'Actualizar botón de pánico',
    read: 'Ver botón de pánico',
    delete: 'Eliminar botón de pánico',
    delete_confirm: 'Estás seguro de eliminar el botón de pánico?'
  },
  maintenance: {
    name: 'Mantenimiento',
    plural_name: 'Mantenimientos',
    create: 'Nuevo mantenimiento',
    update: 'Actualizar mantenimiento',
    read: 'Ver mantenimiento',
    delete: 'Eliminar mantenimiento',
    delete_confirm: 'Estás seguro de eliminar el mantenimiento?'
  },
  fuelPrice: {
    name: 'Precio del combustible',
    plural_name: 'Precios del combustible',
    create: 'Nuevo precio del combustible',
    update: 'Actualizar precio del combustible',
    read: 'Ver precio del combustible',
    delete: 'Eliminar precio del combustible',
    delete_confirm: 'Estás seguro de eliminar el precio del combustible?'
  },
  rule: {
    required: 'Campo requerido',
  },
  ...es,
};
